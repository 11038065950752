export const API_BASE_URL = "https://admin.jamiaabihurairah.com/";
// export const API_BASE_URL_DEV = "https://darulehsan-backend-git-main-jamias-projects.vercel.app/";
export const API_BASE_URL_DEV = "https://darulehsan-backend.vercel.app/";


export const GET_ENROLLMENT_STATUS_API = "course/api/enrollInfo/list/";

export const POST_STUDENT_ENROLL_API = "api/signup/";

export const GET_COURSES_LIST_API = "course/api/course/list/";

export const GET_PDF_LIST_API = "api/download/pdf/";

export const GET_NOTICE_LIST_API = "course/api/notice/list/";

export const GET_CONTACT_US_LIST_API = "api/contact/list/";

export const GET_BANNER_LIST_API = "api/home/slider/";

export const GET_FACULTIES_LIST_API = "api/faculty/list/";

export const GET_ABOUT_US_API = "api/aboutus/list/";

export const GET_TESTIMONIALS_DETAILS_API = "api/testimoniallist/";




