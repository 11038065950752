import React from "react";
import { ABOUT_US, ALL_COURSES_DETAILS, CLASS_DETAILS, COURSE_DETAILS, DOWNLOADS, ENROLL, FACULTIES, FACULTIES_DETAILS, HOME, LOGIN, PROFILE_CLOSE } from "../constant/routepathConstants";
import NotFound from "../../components/NotFound";
const HomeLayout = React.lazy(() => import("../../components/common/layout/homeLayout"))
const MainPage = React.lazy(() => import("../../components/MainPage"))


const Login = React.lazy(() => import("../../pages/auth/login"));
const Enroll = React.lazy(() => import("../../pages/enroll"));
// const CourseDetails = React.lazy(() => import("../../components/courses/CourseDetails"));
const CoursesList = React.lazy(() => import("../../pages/components/CoursesLists"));
const AboutUs = React.lazy(() => import("../../components/AboutOrganisation"));
const FacultyDetails = React.lazy(() => import("../../pages/components/FacultyDetails"));
const ClassDetails = React.lazy(() => import("../../pages/components/ClassDetails"));
const Downloads = React.lazy(() => import("../../pages/components/Downloads"));
const Closedpage = React.lazy(() => import("../../pages/closepage"));




// Routes
const MainRoutes = [
    {
        path: HOME,
        element: <HomeLayout />,
        children: [
            {
                index: true,
                element: <MainPage />,
            },
            {
                path: LOGIN,
                element: <Login />,
            },
            {
                path: ENROLL,
                element: <Enroll />,
            },
            {
                path: ALL_COURSES_DETAILS,
                element: <CoursesList />,
            },
            {
                path: PROFILE_CLOSE,
                element: <Closedpage />,
            },
            // {
            //     path: `${COURSE_DETAILS}/:courseId`,
            //     element: <CourseDetails />,
            // },
            {
                path: ABOUT_US,
                element: <AboutUs />,
            },
            {
                path: FACULTIES_DETAILS,
                element: <FacultyDetails />,
            },
            {
                path: CLASS_DETAILS,
                element: <ClassDetails />,
            },
            {
                path: DOWNLOADS,
                element: <Downloads />,
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
];
export default MainRoutes