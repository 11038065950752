import React from "react";
import { useRoutes } from "react-router-dom";
import LazyLoader from "./components/LazyLoader";
import { getRoutes } from "./config/routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Notice from "./components/Notice";

const userDetails = {
  userType: "admin"
};

const App = () => {
  const role = userDetails?.userType === "admin" ? 2 : 1;
  const routes = getRoutes(role);
  const route = useRoutes(routes);

  return (
    <div>
      <LazyLoader>
        {route}
        <Notice />
      </LazyLoader>
      <ToastContainer />
    </div>
  );
};

export default App;
