import React, { useEffect, useState } from 'react';
import "./noticeStyles.scss";
import { API_BASE_URL, GET_NOTICE_LIST_API } from '../../config/constant/apiConstants';
import axios from 'axios';
import { devConsoleLog, errorToast } from '../../utils/projectHelpers';

const Notice = () => {
    const [loading, setLoading] = useState(true);
    const [notice, setNotice] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const response = await axios.get(`${API_BASE_URL}${GET_NOTICE_LIST_API}`);

                const { data, message } = response.data;

                setLoading(false);

                if (response.status === 200) {
                    setNotice(response?.data);
                } else {
                    errorToast(message);
                }
            } catch (error) {
                setLoading(false);

                const { message } = error.response?.data || {};

                devConsoleLog(error);
                errorToast(message || 'An error occurred while fetching Notice Message.');
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {notice?.length > 0 && (
                <div className="notice">
                    <div className="marquee-container">
                        <div className="marquee">
                            {notice.map((item, index) => (
                                <p key={index}>{item?.text}</p>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Notice;
