import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { ENROLL, LOGIN } from "../constant/routepathConstants";
import GuestLayout from "../../components/common/layout/guestLayout";

const Login = React.lazy(() => import("../../pages/auth/login"));
const Enroll = React.lazy(() => import("../../pages/enroll"));
const NotFound = React.lazy(() => import("../../components/NotFound"));

const guestRoutes = [
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: LOGIN,
        element: <Login />,
      },
      {
        path: ENROLL,
        element: <Enroll />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default guestRoutes;
