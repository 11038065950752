import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slicers/authSlice";
import userReducer from "./slicers/userSlice";


const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,

})

const store = configureStore({ reducer: rootReducer });
export default store;
