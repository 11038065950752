import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        courseList: [],
        facultyList: []

    },
    reducers: {
        getCourseList: (state, { payload }) => {
            state.courseList = payload;
        },
        getFacultyList: (state, { payload }) => {
            state.facultyList = payload;
        },


    },
});

export const { getCourseList, getFacultyList } = userSlice.actions;

export const userSelector = (state) => state.user;
const userReducer = userSlice.reducer;
export default userReducer;
