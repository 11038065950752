import { DEFAULT_TOKEN, USER_DATA, USER_TYPE } from "../config/constant/projectConstants.js";
import { toast } from "react-toastify";
import store from "../redux/store.js";
import validateField from "./validateField.js";
import Swal from "sweetalert2";
import { LOGIN } from "../config/constant/routepathConstants.js";
//useCommas

export const numberWithCommasString = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithoutCommasString = (x) => {
  const beforeDecimalStr = x.toString()?.split(".");

  if (beforeDecimalStr[1] !== undefined) {
    return beforeDecimalStr[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + beforeDecimalStr[1];
  } else {
    return beforeDecimalStr[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const numberWithCommasTrunc = (x) => {
  const trunc = x?.toFixed(2);
  return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommasMath = (x) => {
  const trunc = Math?.trunc(x);
  return trunc?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isDev = () => {
  return process.env.NODE_ENV === "development";
};

export const devConsoleLog = (...a) => {
  if (a) {
    if (isDev()) {
    }
  }
};

export const storeLocal = (data = "", tokenName = DEFAULT_TOKEN) => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }
  window.localStorage.setItem(tokenName, data);
};
export const storeSession = (data = "", tokenName = "userData") => {
  if (typeof data === "object") {
    data = JSON.stringify(data);
  }
  window.sessionStorage.setItem(tokenName, data);
};
export const getLocal = (tokenName = DEFAULT_TOKEN) => {
  const localData = window.localStorage.getItem(tokenName);
  let res;
  try {
    res = JSON.parse(localData);
  } catch (err) {
    res = localData;
  }
  return res;
};
export const getUserData = (userData = USER_DATA) => {
  const localData = window.localStorage.getItem(userData);
  let res = {};
  try {
    res = JSON.parse(localData) || {};
  } catch (err) {
    res = localData || {};
  }
  return res;
};
export const getUserDetails = (userData = USER_DATA) => {
  const localData = window.localStorage.getItem(userData);
  let res = {};
  try {
    res = JSON.parse(localData) || {};
  } catch (err) {
    res = localData || {};
  }
  return res;
};
export const removeLocal = (tokenName = DEFAULT_TOKEN) => {
  window.localStorage.removeItem(tokenName);
  return navigate(LOGIN);
};

export const sessionDestroy = (path = LOGIN) => {
  removeLocal();
  removeLocal(USER_DATA);
  // dispatcher(sessionFail());
  navigate(path);
  // dispatcher(setUserInfo({}));
};

export const uploadPathBuilder = (root, a) => {
  return root + a;
};

export const navigate = (path) => {
  // dispatcher(navigatePath(path));
};

export const dispatcher = (a) => {
  store.dispatch(a);  
};

export const successToast = (a) =>
  toast.success(a, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });

export const errorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const warningToast = () => {
  toast.warn("Something went wrong", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
export const infoToast = () => {
  toast.info("Network Error!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

export const errorValidator = (a, setState) => {
  const { name, value = "", password } = a;
  if (value.trim()) {
    const { error = null } = validateField(name, value, password);
    setState((err) => {
      return { ...err, [name]: error };
    });
  } else {
    setState((err) => {
      return { ...err, [name]: null };
    });
  }
};
export const errorValidatorPassword = (a, setState) => {
  const { name, value = "" } = a;
  if (value.trim()) {
    const { error = null } = validateField(name, value);
    setState((err) => {
      return { ...err, [name]: error };
    });
  } else {
    setState((err) => {
      return { ...err, [name]: null };
    });
  }
};

export const errorAlert = (msg) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: msg,
  });
};

export const SuccessAlert = (msg) => {
  Swal.fire({
    icon: "success",
    title: msg,
    showConfirmButton: false,
    timer: 5000,
  });
};
export const ConfirmAlert = (msg, navigate, path) => {
  Swal.fire({
    title: msg,
    icon: "success",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "GO BACK TO LOGIN",
  }).then((result) => {
    if (result.isConfirmed) {
      navigate(path);
    }
  });
};


export const blockInvalidChar = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
