// GuestLayout.js
import React, { lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ENROLL, LOGIN } from "../../../config/constant/routepathConstants";
const NavBar = React.lazy(() => import("./Navbar"))
const Footer = React.lazy(() => import("./Footer"))



const noLayout = [ENROLL, LOGIN];

const GuestLayout = () => {
  const { pathname } = useLocation();
  const hasLayout = !noLayout.includes(pathname);
  return (
    <div className={hasLayout ? "guest-page" : ""}>
      {hasLayout && <NavBar />}
      <Outlet />
      {hasLayout && <Footer />}
    </div>
  );
};

export default GuestLayout;
