import guestRoutes from "./guestRoutes";
import mainRoutes from "./MainRoutes";


const ROLES_ROUTES = {
  1: guestRoutes,
  2: mainRoutes,
};

export const getRoutes = (role) => {
  return ROLES_ROUTES[role];
};
