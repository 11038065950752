export const HOME = "/";
export const LOGIN = "/login";
export const ENROLL = "/enroll";

export const PROFILE_CLOSE = "close";
export const ABOUT_US = "about-us"
export const ALL_COURSES_DETAILS = "courses"
export const COURSE_DETAILS = "course-details"
export const FACULTIES_DETAILS = "faculties"
export const NOTICE = "notice"
export const CLASS_DETAILS = "class-details"
export const DOWNLOADS = "downloads"
